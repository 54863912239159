import { PlatformUtils } from '@eo-storefronts/eo-core'
import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesOrderServiceInterface from '~/src/components/orders/order-layout/styles/StylesOrderServiceInterface'

export default class StylesOrderServiceStyleTwo extends TemplateServiceStyleBase implements StylesOrderServiceInterface {
  public getPageSx(offset = 0): SxProps {
    return {
      pt: 4,
      minHeight: '100%',
      [this.muiTheme.breakpoints.down('md')]: {
        p: 2,
        pt: this._getDownMdPtForPageSx(offset)
      }
    }
  }

  private _getDownMdPtForPageSx(offset: number) {
    if (!PlatformUtils().isCapacitorNative) {
      return 2
    }

    if (this.layout?.header.display) {
      return 0
    }

    if (offset !== 0) {
      return this.muiTheme.spacing(3)
    }

    return `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(3)})`
  }
}
